<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Licensee Details
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="licenseeDetailsLoading"
        class="flex justify-center p-16"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div v-else class="py-2 px-4">
        <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-input
            v-model="dealSummaryData.licenseeDetails.licensee"
            type="text"
            container-class="flex items-center"
            disabled
            label="Licensee"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.licensor"
            type="text"
            container-class="flex items-center"
            disabled
            label="Licensor"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.description"
            type="text"
            container-class="flex items-center"
            disabled
            label="Description"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.contractNumber"
            type="text"
            container-class="flex items-center"
            disabled
            label="Contract Number"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.startDate"
            type="text"
            container-class="flex items-center"
            disabled
            label="Start Date"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.endDate"
            type="text"
            container-class="flex items-center"
            disabled
            label="End Date"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.contractType"
            type="text"
            container-class="flex items-center"
            disabled
            label="Contract Type"
            label-class="label-w-46"
          />
          <base-input
            v-model="dealSummaryData.licenseeDetails.contractStatus"
            type="text"
            container-class="flex items-center"
            disabled
            label="Status"
            label-class="label-w-46"
          />
        </div>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, onMounted, computed, ref } from 'vue';
import useDealSummary from './dealSummary.js';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
    name: 'LicenseeDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();
        const { dealSummaryData } = useDealSummary();

        onMounted(() => {
            fetchLicenseeDetails();
        });

        const fetchedLicenseeContractDetails = computed(() => store.getters['baLicensee/getLicenseeContractDetailsList']);
        const licenseeDetailsLoading = ref(false);
        const fetchLicenseeDetails = async () => {
            try {
                licenseeDetailsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmLicenseeContractDetails', {
                    id: dealSummaryData.contractNumber
                });
                dealSummaryData.licenseeDetails.contractNumber = fetchedLicenseeContractDetails.value.contractNumber;
                dealSummaryData.licenseeDetails.licensee = fetchedLicenseeContractDetails.value.licensee;
                dealSummaryData.licenseeDetails.licensor = fetchedLicenseeContractDetails.value.licensor;
                dealSummaryData.licenseeDetails.startDate = moment(fetchedLicenseeContractDetails.value.contractStartDate).format('MM/DD/YYYY');
                dealSummaryData.licenseeDetails.endDate = moment(fetchedLicenseeContractDetails.value.contractEndDate).format('MM/DD/YYYY');
                dealSummaryData.licenseeDetails.contractType = fetchedLicenseeContractDetails.value.contractType;
                const contractStatus = fetchedLicenseeContractDetails.value.contractStatus;
                dealSummaryData.licenseeDetails.contractStatus = contractStatus === 'TERMINATED' ? 'EXPIRED' : contractStatus;
                dealSummaryData.licenseeDetails.description = fetchedLicenseeContractDetails.value.contractDescription;
            } catch (err) {
                console.error(err);
            } finally {
                licenseeDetailsLoading.value = false;
            }
        };
        return {
            dealSummaryData,
            fetchLicenseeDetails,
            licenseeDetailsLoading
        };
    }
};
</script>
